




import { Component, Vue } from 'vue-property-decorator';
import 'vanilla-cookieconsent/dist/cookieconsent.css';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
let cookieConsent: any = undefined;

@Component({})
export default class CookieConsentPlugin extends Vue {
  public showSettings() {
    cookieConsent.showSettings(0);
  }

  public hideSettings() {
    cookieConsent.hideSettings();
  }

  mounted() {
    window.addEventListener('load', () => {
      // @ts-ignore
      if (!window.initCookieConsent) {
        return;
      }
      // @ts-ignore
      cookieConsent = window.initCookieConsent();
      const cookie = '🍪';
      const gtag = this.$gtag;
      cookieConsent.run({
        current_lang: 'cs',
        autoclear_cookies: true,
        cookie_name: 'kkd_cookie_consent',
        cookie_expiration: 365,
        page_scripts: true,

        force_consent: true,

        gui_options: {
          consent_modal: {
            layout: 'box', // box,cloud,bar
            position: 'middle center', // bottom,middle,top + left,right,center
            transition: 'slide' // zoom,slide
          },
          settings_modal: {
            layout: 'box', // box,bar
            transition: 'slide' // zoom,slide
          }
        },
        onAccept: function () {
          if (cookieConsent && gtag) {
            if (cookieConsent.allowedCategory('analytics')) {
              // @ts-ignore - consent is in beta, missing types
              gtag.query('consent', 'update', {
                analytics_storage: 'granted'
              });
            }

            if (cookieConsent.allowedCategory('ads')) {
              // @ts-ignore - consent is in beta, missing types
              gtag.query('consent', 'update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted'
              });
            }
          }
        },
        onChange: function () {
          if (cookieConsent && gtag) {
            if (cookieConsent.allowedCategory('analytics')) {
              // @ts-ignore - consent is in beta, missing types
              gtag.query('consent', 'update', {
                analytics_storage: 'granted'
              });
            } else {
              // @ts-ignore - consent is in beta, missing types
              gtag.query('consent', 'update', {
                analytics_storage: 'denied'
              });
            }

            if (cookieConsent.allowedCategory('ads')) {
              // @ts-ignore - consent is in beta, missing types
              gtag.query('consent', 'update', {
                ad_storage: 'granted',
                ad_user_data: 'granted',
                ad_personalization: 'granted'
              });
            } else {
              // @ts-ignore - consent is in beta, missing types
              gtag.query('consent', 'update', {
                ad_storage: 'denied',
                ad_user_data: 'denied',
                ad_personalization: 'denied'
              });
            }
          }
        },
        languages: {
          cs: {
            consent_modal: {
              title: `${cookie} Tato webová stránka používá cookies!`,
              description:
                'K personalizaci obsahu a reklam, poskytování funkcí sociálních médií a analýze naší návštěvnosti využíváme soubory cookie. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro sociální média, inzerci a analýzy.',
              primary_btn: {
                text: 'Povolit vše',
                role: 'accept_all'
              },
              secondary_btn: {
                text: 'Manuální nastavení',
                role: 'settings'
              }
            },
            settings_modal: {
              title: 'Nastavení cookies',
              save_settings_btn: 'Uložit',
              accept_all_btn: 'Přijmout vše',
              reject_all_btn: 'Odmítnout vše',
              close_btn_label: 'Zavřít',
              cookie_table_headers: [{ col1: 'Název' }, { col2: 'Doména' }, { col3: 'Expirace' }, { col4: 'Popis' }],
              blocks: [
                {
                  title: 'Využití cookies 📢',
                  description:
                    'K personalizaci obsahu a reklam, poskytování funkcí sociálních médií a analýze naší návštěvnosti využíváme soubory cookie. Informace o tom, jak náš web používáte, sdílíme se svými partnery pro sociální média, inzerci a analýzy. Partneři tyto údaje mohou zkombinovat s dalšími informacemi, které jste jim poskytli nebo které získali v důsledku toho, že používáte jejich služby.'
                },
                {
                  title: 'Nutné soubory',
                  description: 'Tyto soubory jsou nezbytné pro správné fungování webu',
                  toggle: {
                    value: 'necessary',
                    enabled: true,
                    readonly: true
                  },
                  cookie_table: [
                    {
                      col1: 'cc_cookie_consent',
                      col2: 'kakadoo.cz',
                      col3: '2 roky',
                      col4: 'Uchování nastavení vašich cookie preferencí'
                    }
                  ]
                },
                {
                  title: 'Výkonostní a analytické cookies',
                  description: 'Soubory používané k zapamatování si vašeho nastavení, které jste provedli v minulosti',
                  toggle: {
                    value: 'analytics', // your cookie category
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    {
                      col1: '^_ga',
                      col2: 'google.com',
                      col3: '2 roky',
                      col4: 'Společnost Google používá tyto soubory cookies na základě posledních vyhledávání a interakcí pro interní analýzu chování zákazníků',
                      is_regex: true
                    },
                    {
                      col1: '_gid',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'Společnost Google používá tyto soubory cookies na základě posledních vyhledávání a interakcí pro interní analýzu chování zákazníků'
                    },
                    {
                      col1: 'CONSENT',
                      col2: 'google.com',
                      col3: '1 day',
                      col4: 'Společnost Google používá tyto soubory cookies na základě posledních vyhledávání a interakcí pro interní analýzu chování zákazníků'
                    }
                  ]
                },
                {
                  title: 'Reklamní a retargetingové cookies',
                  description: 'Soubory využívané ke sledování chování na webu a přizpůsobení inzertních systémů',
                  toggle: {
                    value: 'ads', // category "ads"
                    enabled: false,
                    readonly: false
                  },
                  cookie_table: [
                    {
                      col1: 'ad_storage',
                      col2: 'google.com',
                      col3: '-',
                      col4: 'Společnost Google používá tyto soubory cookies na základě posledních vyhledávání a interakcí pro interní analýzu chování zákazníků'
                    },
                    {
                      col1: 'ad_user_data',
                      col2: 'google.com',
                      col3: '-',
                      col4: 'Souhlas s odesíláním údajů o uživatelích'
                    },
                    {
                      col1: 'ad_personalization',
                      col2: 'google.com',
                      col3: '-',
                      col4: 'Souhlas s personalizovanou inzercí'
                    }
                  ]
                },
                {
                  title: 'Více informací',
                  description:
                    'Pro více informací nás prosím <a class="cc-link" href="mailto:info@kakadoo.cz">kontaktujte</a>.'
                }
              ]
            }
          }
        }
      });
    });
  }
}
